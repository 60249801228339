import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import * as Yup from "yup";

import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import { COLOR_STATUS_SNIPPET, TYPE_POST } from "helpers/constans";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, UploadProps } from "antd";
import SnippetApi, { uploadFiles } from "api/snippetApi";
import { mockAuthorId } from "utils";
import ListNewsSection from "./ListNewsSection";
import { set } from "lodash";
import { error } from "console";
import { getCategoryById } from "store/thunks";
import DropdownArtist from "components/Common/DropdownArtist";
import DropdownGroup from "components/Common/DropdownGroup";
import FeatherIcon from "feather-icons-react";
import IconDeltete from "../../assets/images/icon-delete.png"

export default function CreateEditPhotoNews({
  setIsOpen,
  dispatch,
  query,
  item,
  setItemToEdit,
  loadTableData,
  listArtist,
  listGroup,
  setLinkImage,
  STATUS_SNIPPET_OPTION_LANG
}: any) {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingUploadImage, setIsLoadingUploadImage] = useState<boolean>(false);
  const [summarizeContent, setSummarizeContent] = useState<any>('');
  
  const isEdit = item;

  const props: UploadProps = {
    name: "files",
    beforeUpload: () => false,
    headers: {
      authorization: "authorization-text",
    },
  };

  const propsThumbnail: UploadProps = {
    name: "files",
    accept: '.png,.jpg,.webp,.jpeg',
    beforeUpload: () => false,
    headers: {
      authorization: "authorization-text",
    },
  };

  const handleSubmit = async (values: any) => {
    
    try {
      setIsLoading((_prev) => true);
      const data = {
        title: values?.title ?? "",
        status: values?.status?.value || "activated",
        content: values?.content ?? "",
        artistIds: !!values?.artistId?.value ? [values?.artistId?.value] : [],
        categoryIds: !!values?.categoryId?.value ? [values?.categoryId?.value] : [],
        thumbnail_url: values?.thumbnail_url,
        media_url: values?.files?.[0]?.url,
        media_type: values?.files?.[0]?.media_type || "image",
        authorId: mockAuthorId,
        originalContent: values?.originalContent,
        sourceNewsId: `${values?.sourceNewsId}`,
        postedAt: new Date(),
      };

      const response: any = isEdit
        ? await SnippetApi?.updateSnippet(item?._id, data)
        : await SnippetApi?.createSnippet(data);
      if (response?.data) {
        //  dispatch(onGetSnippets(query));
        loadTableData();
        setIsLoading((_prev) => false);
        setIsOpen(false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsLoading(false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading(false);
      toast(`Please re-check your data`);
      return error;
    }
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(`${t("Title is required")}`),
    content: Yup.string().required(`${t("Content is required.")}`),
    thumbnail_url: Yup.string().required(`${t("Thumbnail is required.")}`),
    // media_url: Yup.string().required(`${t("Video is required")}`),
    // short_description: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      status: STATUS_SNIPPET_OPTION_LANG[1],
      content: "",
      description: "",
      // short_description: "",
      artistId: null,
      categoryId: null,
      thumbnail_url: "",
      media_url: "",
      media_type: "",
      sourceNewsId: null,
      originalContent: "",
      files: []
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const [lstFormData, setLstFormData] = useState<any>(item);

  useEffect(() => {
    if(lstFormData?.originalContent) {
      setLoadingSummarize(false)
    }

    const vStatus =
      STATUS_SNIPPET_OPTION_LANG?.filter(
        (i: any) => String(i?.value) == String(lstFormData?.status)
      )[0] || STATUS_SNIPPET_OPTION_LANG[1];

    formik.setFieldValue("title", lstFormData?.title);
    formik.setFieldValue("status", vStatus);
    formik.setFieldValue("content", lstFormData?.content);
    formik.setFieldValue("description", lstFormData?.description);
    formik.setFieldValue("sourceNewsId", lstFormData?.sourceNewsId);
    formik.setFieldValue("originalContent", lstFormData?.originalContent);
    formik.setFieldValue("thumbnail_url", lstFormData?.thumbnail_url);
    // formik.setFieldValue("artistId", lstFormData?.artists?.[0]?._id);

    const formatedFile = [
      {
        uid: "-1",
        name: "image.png",
        status: "done",
        url: lstFormData?.media_url,
        thumbUrl: lstFormData?.media_url,
        ...lstFormData,
      },
    ];
    formik.setFieldValue("files", formatedFile);


    setTimeout(() => {
      formik.setFieldValue("artistId", { value: `${lstFormData?.artists?.[0]?._id}`, label: '' });
      formik.setFieldValue("categoryId", { value: `${lstFormData?.categoryIds?.[0]}`, label: '' });
    }, 300);
  }, [lstFormData]);


  const onCloseClick = () => {
    setIsOpen(false);
    setItemToEdit(false);
  };

  const handleOnBlur = (e:React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldTouched(e.target.name)
  }

  const handleCopyDataNew = (data: any) => {
    setLstFormData(data)
  }

  const [isLoadingSummarize, setLoadingSummarize] = useState(true);
  const [isLoadingGenerate, setLoadingGenerate] = useState(false);
  
  const handleSummarize = async (data: string) => {
    if(!data) return;

    setLoadingGenerate(true)
    setLoadingSummarize(true)
    const res: any = await SnippetApi?.summarizePhotoNews({content: data });

    if (res?.data) {
      setLoadingGenerate(false)
      setLoadingSummarize(false)
      formik.setFieldValue("content", res?.data?.summary);
    }else {
      setLoadingGenerate(false)
      setLoadingSummarize(false)
    }

  }

  const getCategory = async (id: string) => {
    if(!id) return;
    const res: any = await getCategoryById(id);
    if(res?.success && res?.data) {
      formik.setFieldValue("categoryId", { value: `${res?.data?._id}`, label: '' });
    }

    return;
  }

  const handleChangeArtist = (e: any) => {
    formik.setFieldValue("artistId", e);
    formik.setFieldValue("categoryId", null);

    if(e?.value && listArtist) {
      const artist = listArtist.find((i: any) => i?._id == e?.value);
      if(artist?.categoryIds?.length > 0) {
        getCategory(artist?.categoryIds?.[0]);
      }
    }
  }

  return (
    <Modal isOpen={true} centered={true} size="xl" className="modal-fullscreen" scrollable={false}>
      <ModalHeader toggle={isLoading ? () => {} : onCloseClick}>
        {isEdit ? t("Update Photo News") : t("Create Photo News")}
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <div className="row g-4">
          <Col md={4} className="order-sm-2 order-md-1 px-0 px-md-3 position-relative">
              <ListNewsSection
                handleCopyDataNew={handleCopyDataNew}
              
              />
          </Col>
          <Col md={8} className="order-sm-1 order-md-2 py-3 box-template-form" style={{maxHeight: "unset", minHeight: "calc(-100px + 100dvh)", overflowY: "unset"}}>
            <form onSubmit={formik.handleSubmit}>
              <div className="row g-3">
                <Col xxl={12}>
                  <label className="form-label">
                    {t("Title")}
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    className="form-control"
                    id="title"
                    name="title"
                    value={formik?.values?.title}
                    placeholder={`${t("Title")}...`}
                    onChange={(event: any) =>
                      formik.setFieldValue("title", event?.target?.value || "")
                    }
                    onBlur={handleOnBlur}
                  />
                  {formik.touched.title && formik.errors.title ? (
                    <div className="text-danger mt-2">{formik.errors.title}</div>
                  ) : null}
                </Col>

                <Col xxl={12}>
                  <label className="form-label">
                    {t("Original Content")}
                  </label>
                  <textarea
                    className="form-control"
                    name="originalContent"
                    disabled
                    value={formik?.values?.originalContent}
                    placeholder={`${t("Original Content")}...`}
                    style={{ height: 200 }}
                  />
                </Col>

                <Col>
                  <div className="col-sm-auto">
                      <button
                        type="button"
                        className="btn btn-success d-none d-md-block"
                        disabled={isLoadingSummarize}
                        onClick={() => handleSummarize(formik?.values?.originalContent)}
                      >
                        {(isLoadingSummarize && isLoadingGenerate)  && (
                          <Spinner size="sm" className="me-2"></Spinner>
                        )
                      }
                        
                        {t("Summarize")}
                      </button> 
                    </div>
                </Col>

                <Col xxl={12}>
                  <label className="form-label">
                    {t("Summarize Content")} <span className="text-danger"> *</span>
                  </label>
                  <textarea
                    className="form-control"
                    id="content"
                    name="content"
                    value={formik?.values?.content}
                    placeholder={`${t("Content")}...`}
                    onChange={(event: any) =>
                      formik.setFieldValue("content", event?.target?.value || "")
                    }
                    onBlur={()=> formik.setFieldTouched("content")}
                    style={{ height: 200 }}
                  />
                  {formik.touched.content && formik.errors.content ? (
                    <div className="text-danger mt-2">{formik.errors.content}</div>
                  ) : null}
                </Col>


                <Col xxl={12}>
                  <label className="form-label">
                    {t("Artist")}
                  </label>
                  <DropdownArtist
                    name="artist"
                    dataList={listArtist || []}
                    placeholder={`${t("Select Artist")}...`}
                    className="dropdown-status-rounded"
                    classNamePrefix="name-prefix"
                    initialValue={formik?.values?.artistId || null}
                    onChangeSelect={(e: any) => handleChangeArtist(e)}
                    isHasOptionAll={false}
                    isClearable={true}
                  />
                  {formik.touched.artistId && formik.errors.artistId ? (
                    <div className="text-danger mt-2">
                      {formik.errors.artistId}
                    </div>
                  ) : null}
                </Col>

                <Col xxl={12}>
                  <label className="form-label">
                    {t("Group")}
                  </label>
                  <DropdownGroup
                    name="categoryId"
                    dataList={listGroup || []}
                    placeholder={`${t("Select Group")}...`}
                    className="dropdown-status-rounded"
                    classNamePrefix="name-prefix"
                    initialValue={formik?.values?.categoryId || null}
                    onChangeSelect={(e: any) => formik.setFieldValue("categoryId", e)}
                    isHasOptionAll={false}
                    isClearable={true}
                  />
                </Col>

                {/* <Col xxl={12}>
                  <label className="form-label">
                    {t("Artist")}
                  </label>
                  <Select
                    placeholder={<div>{t('Select Artist')}...</div>}
                    name="artistId"
                    options={showArtist}
                    value={showArtist.find((i: any) => {
                      if (formik?.values?.artistId)
                        return formik?.values?.artistId == i?.value;
                    })}
                    onChange={(event: any) =>
                      formik.setFieldValue("artistId", event?.value)
                    }
                  />
                </Col> */}

                <Col lg={4} className="mt-3">
                  <div className="mb-3">
                    <label className="form-label">
                      {t("Thumbnail")}
                      <span className="text-danger"> *</span>
                    </label>

                    <div>
                      {formik.getFieldProps("thumbnail_url")?.value && (
                        <div
                          style={{
                            display: "flex",
                            gap: 10,
                            marginBottom: 10,
                            marginTop: 10,
                          }}
                        >
                          <div style={{position: "relative"}}>
                            <img
                              alt="thumbnail_url"
                              src={formik.getFieldProps("thumbnail_url")?.value}
                              style={{ maxHeight: 120, maxWidth: 160 }}
                              onClick={()=> {
                                setLinkImage({thumbnail_url: formik.getFieldProps("thumbnail_url")?.value });
                              }}
                            />
                            
                            <span 
                              onClick={() => formik.setFieldValue("thumbnail_url", "")}
                              style={{color: "red", position: "absolute", top: "-10px", right: "-5px", cursor: "pointer"}}>
                              <img src={IconDeltete} alt="delete" width={14} height={14} />
                            </span>
                          </div>
                        </div>
                      )}
                    <div>
                      {(isLoadingUploadImage)  && (
                          <Spinner size="sm" className="me-2" style={{color: "#4da3da"}}></Spinner>
                        )
                      }
                    </div>
                      <Upload
                        {...propsThumbnail}
                        multiple={false}
                        beforeUpload={() => false}
                        //   listType="picture-card"
                        // fileList={(formik.getFieldProps("files")?.value as any) || []}
                        fileList={[]}
                        onChange={async (info: any) => {
                          setIsLoadingUploadImage(true)
                          try {
                            const files = info.fileList;
                            const data = new FormData();
                            for (var i = 0; i < files?.length; i++) {
                              data.append("files", files[i]?.originFileObj);
                            }
  
                            const res = (await uploadFiles(data)) as any;
                            if (res?.success) {
                              setIsLoadingUploadImage(false)
                              const fortmatedData = res?.data?.map(
                                (i: any, index: number) => {
                                  return {
                                    type: "image",
                                    url: i?.url,
                                    preview: i?.url,
                                    elements: [],
                                    caption: "Image",
                                    mimetype: "image/*",
                                    width: 1080,
                                    height: 1080,
                                    ratio: 1.1,
                                    seq: 0,
                                    ...i,
                                  };
                                }
                              );
  
                              formik.setFieldValue(
                                "thumbnail_url",
                                fortmatedData?.[0]?.url
                              );
                            }else{
                              setIsLoadingUploadImage(false)
                            }
                          }catch {
                            setIsLoadingUploadImage(false)
                          }
                        }}
                        onRemove={() => {
                          //return onGalleryFileRemove();
                        }}
                      >
                        <Button style={{height: "38px"}} icon={<UploadOutlined />}>
                          {t("Click to Upload")}
                        </Button>
                      </Upload>
                    </div>
                    {formik?.touched?.thumbnail_url && formik?.errors?.thumbnail_url ? (
                      <div className="text-danger mt-2">
                        {formik?.errors?.thumbnail_url}
                      </div>
                    ) : null}
                  </div>
                </Col>

                <Col lg={3} className="mt-3">
                  <label className="form-label">
                    {t("Status")}
                    <span className="text-danger"> *</span>
                  </label>
                  
                  <Select
                    name="status"
                    className="mb-0 dropdown-status-rounded"
                    classNamePrefix="name-prefix"
                    placeholder={t("Status")}
                    value={formik?.values?.status || STATUS_SNIPPET_OPTION_LANG[1]}
                    onChange={(event: any) =>
                      formik.setFieldValue("status", event)
                    }
                    options={STATUS_SNIPPET_OPTION_LANG}
                    formatOptionLabel={(option: any) => (
                      <div className="d-flex align-items-center">
                        <span className={`bg-${option?.value !== '' ? COLOR_STATUS_SNIPPET?.[option?.value] : 'secondary'}`} style={{ width: "15px", height: '15px', borderRadius: '3px' }}></span>
                        <div className="ms-2">
                          {option?.label}
                        </div>
                      </div>
                    )}
                  ></Select>
                  {formik?.touched?.status && formik?.errors?.status ? (
                    <div className="text-danger mt-2">
                    </div>
                  ) : null}
                </Col>

                <div className="col-lg-12">
                  <div className="hstack gap-2 justify-content-end">
                    <button
                      className="btn btn-primary fs-14"
                      color="success"
                      type="submit"
                      // disabled={isLoading}
                      disabled={
                        ((formik.errors && Object.keys(formik.errors).length > 0) || isLoading )
                          ? true
                          : false
                      }
                    >
                      {isLoading ? (
                        <Spinner size="sm" className="me-2"></Spinner>
                      ) : isEdit ? (
                        <i className="ri-login-circle-line align-bottom me-1"></i>
                      ) : (
                        <i className="ri-add-fill align-bottom me-1"></i>
                      )}
                      {isEdit ? t("Update Photo News") : t("Create Photo News")}
                    </button>
                    <button
                      className="btn btn-soft-secondary fs-14"
                      color="light"
                      type="button"
                      onClick={onCloseClick}
                      disabled={isLoading}
                    >
                      <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                      {t("Button Close")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Col>
        </div>
        
      </ModalBody>
    </Modal>
  );
}
