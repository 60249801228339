import { useFormik } from "formik";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import * as Yup from "yup";

import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, UploadProps } from "antd";
import PostApi, { uploadFiles } from "api/postApi";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownArtist from "components/Common/DropdownArtist";
import { TYPE_POST } from "helpers/constans";
import { mockAuthorId } from "utils";
import { getCategory, getCategoryById } from "store/thunks";
import DropdownGroup from "components/Common/DropdownGroup";
import IconDeltete from "../../assets/images/icon-delete.png"

export default function CreateEditPost({
  setIsOpen,
  dispatch,
  query,
  item,
  setItemToEdit,
  loadTableData,
  listArtist,
  listGroup,
  setLinkImage,
}: any) {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [linkImageEdit, setLinkImageEdit] = useState<any>(null);
  const [isLoadingUploadImage, setIsLoadingUploadImage] = useState<boolean>(false);

  const isEdit = item;

  const props: UploadProps = {
    name: "files",
    beforeUpload: () => false,
    headers: {
      authorization: "authorization-text",
    },
  };

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading((_prev) => true);
      const data = {
        title: values?.title ?? "",
        description: values?.description ?? "",
        type: "feed",
        artistIds: !!values?.artistId?.value ? [values?.artistId?.value] : [],
        categoryIds: !!values?.categoryId?.value ? [values?.categoryId?.value] : [],
        status: "activated",
        tagIds: [],
        files: values?.files,
        authorId: mockAuthorId,
      };

      const response: any = isEdit
        ? await PostApi?.updatePost(item?._id, data)
        : await PostApi?.createPost(data);
      if (response?.data) {
        //  dispatch(onGetPosts(query));
        loadTableData();
        setIsLoading((_prev) => false);
        setIsOpen(false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsLoading(false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading(false);
      toast(`Please re-check your data`);
      return error;
    }
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(`${t("Post Name is required")}`).max(5000, `${t("Post Name must be at most 5000 characters long")}`),
    description: Yup.string().nullable().max(50000, `${t("Description must be at most 50000 characters long")}`),
    files: Yup.array().min(1).required(`${t("File is required")}`)
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      type: TYPE_POST[0],
      files: [],
      artistId: null,
      categoryId: null
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    const formatedFile = item?.files?.map((i: any) => {
      return {
        uid: "-1",
        name: "image.png",
        url: i?.url,
        thumbUrl: i?.url,
        ...i,
      };
    });
    formik.setFieldValue("files", formatedFile);
    formik.setFieldValue("title", item?.title);
    formik.setFieldValue("description", item?.description);

    setTimeout(() => {
      formik.setFieldValue("artistId", { value: `${item?.artists?.[0]?._id}`, label: '' });
      formik.setFieldValue("categoryId", { value: `${item?.categoryIds?.[0]}`, label: '' });
    }, 300);
  }, [item]);

  const onCloseClick = () => {
    setIsOpen(false);
    setItemToEdit(false);
  };


  const getCategory = async (id: string) => {
    const res: any = await getCategoryById(id);
    if(res?.success && res?.data?._id) {
      formik.setFieldValue("categoryId", { value: `${res?.data?._id}`, label: '' });
    }

    return;
  }

  const handleChangeArtist = (e: any) => {
    formik.setFieldValue("artistId", e);

    if(e?.value && listArtist && !item) {
      console.log("cdscds", item)

      const artist = listArtist.find((i: any) => i?._id == e?.value);
      if(artist?.categoryIds?.length > 0) {
        getCategory(artist?.categoryIds?.[0]);
      }else {
        formik.setFieldValue("categoryId", null);
      }
    }
  }

  return (
    <Modal isOpen={true} centered={true} scrollable={false} size="lg">
      <ModalHeader toggle={isLoading ? () => { } : onCloseClick}>
        {isEdit ? t("Update Feed") : t("Create Feed")}
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <form onSubmit={formik.handleSubmit}>
          <div className="row g-3">
            <Col xxl={12}>
              <label className="form-label">
                {t("Title")}
                <span className="text-danger"> *</span>
              </label>
              <input
                className="form-control"
                id="title"
                name="title"
                value={formik?.values?.title}
                placeholder={`${t("Title")}...`}
                onChange={(event: any) =>
                  formik.setFieldValue("title", event?.target?.value || "")
                }
              />
              {formik.touched.title && formik.errors.title ? (
                <div className="text-danger mt-2">{formik.errors.title}</div>
              ) : null}
            </Col>

            <Col xxl={12}>
              <label className="form-label">
                {t("Content")} <span className="text-danger"> *</span>
              </label>
              <textarea
                className="form-control"
                id="description"
                name="description"
                value={formik?.values?.description}
                placeholder={`${t("Content")}...`}
                onChange={(event: any) =>
                  formik.setFieldValue(
                    "description",
                    event?.target?.value || ""
                  )
                }
                style={{ height: 150 }}
              />
              {formik.touched.description && formik.errors.description ? (
                <div className="text-danger mt-2">
                  {formik.errors.description}
                </div>
              ) : null}
            </Col>

            <Col xxl={12}>
              <label className="form-label">
                {t("Artist")}
              </label>
              <DropdownArtist
                name="artist"
                dataList={listArtist || []}
                placeholder={`${t("Select Artist")}...`}
                className="dropdown-status-rounded"
                classNamePrefix="name-prefix"
                initialValue={formik?.values?.artistId || null}
                onChangeSelect={(e: any) =>handleChangeArtist(e)}
                isHasOptionAll={false}
                isClearable={true}
              />
            </Col>

            <Col xxl={12}>
              <label className="form-label">
                {t("Group")}
              </label>
              <DropdownGroup
                name="categoryId"
                dataList={listGroup || []}
                placeholder={`${t("Select Group")}...`}
                className="dropdown-status-rounded"
                classNamePrefix="name-prefix"
                initialValue={formik?.values?.categoryId || null}
                onChangeSelect={(e: any) => {
                  formik.setFieldValue("categoryId", e)
                }}
                isHasOptionAll={false}
                isClearable={true}
              />
            </Col>

            <Col xxl={12}>
              <label className="form-label">
                {t("Files")}
                <span className="text-danger"> *</span>
              </label>

              {formik.getFieldProps("files")?.value && (
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    marginBottom: 10,
                    marginTop: 10,
                  }}
                >
                  {formik.getFieldProps("files")?.value?.map((i: any, index: number) => {
                    if (i?.type == "video")
                      return (
                        <div
                          style={{
                            position: "relative",
                            height: "100%",
                            cursor: "pointer",
                          }}
                          >
                          <video
                            width="160"
                            height="120"
                            controls
                            key={i?.url}
                            onClick={()=> {
                              setLinkImage({url: i?.url, isVideo: true});
                            }}
                          >
                            <source src={i?.url} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                          <span 
                            onClick={() => formik.setFieldValue("files", [])}
                            style={{color: "red", position: "absolute", top: "-10px", right: "-5px", cursor: "pointer"}}>
                              <img src={IconDeltete} alt="delete" width={12} height={12} />
                          </span>

                          {/* <div className="d-flex" style={{
                            height: "100%", top: '0', position: 'absolute', zIndex: '9999999', width: '100%', whiteSpace: 'nowrap', textAlign: 'center', opacity: 0, transition: 'all 0.3s', alignItems: 'center', justifyContent: 'center', gap: '5px',
                          }}>
                            <span><FeatherIcon icon="eye" /></span>
                            <span><FeatherIcon icon="trash" /></span>
                          </div> */}

                        </div>
                      );
                    else
                      return (
                        <div
                          style={{
                            position: "relative",
                          }}>
                          <img
                            alt="thumbnail"
                            src={i?.url}
                            style={{ maxHeight: 120, maxWidth: 160, cursor: "pointer" }}
                            onClick={()=> {
                              setLinkImage({url: i?.url});
                            }}
                          />
                          <span 
                            onClick={() => {
                              const newFilesList = [...formik.getFieldProps("files")?.value];
                              newFilesList.splice(index, 1);
                              formik.setFieldValue("files", newFilesList)
                            }}
                            style={{color: "red", position: "absolute", top: "-12px", right: "-5px", cursor: "pointer"}}>
                              <img src={IconDeltete} alt="delete" width={14} height={14} />
                          </span>
                        </div>
                      );
                  })}
                </div>
              )}
              <div>
                {(isLoadingUploadImage)  && (
                    <Spinner size="sm" className="me-2" style={{color: "#4da3da"}}></Spinner>
                  )
                }
              </div>

              <div>
                <Upload
                  {...props}
                  multiple={true}
                  beforeUpload={() => false}
                  //   listType="picture-card"
                  // fileList={(formik.getFieldProps("files")?.value as any) || []}
                  fileList={[]}
                  onChange={async (info: any) => {
                    setIsLoadingUploadImage(true) 
                    try {
                      const cloneInfo = info?.fileList?.length
                      ? [...info?.fileList]
                      : [];

                      const files = info.fileList;
                      const data = new FormData();
                      for (var i = 0; i < files?.length; i++) {
                        data.append("files", files[i]?.originFileObj);
                      }

                      const res = (await uploadFiles(data)) as any;
                      if (res?.success) {
                        setIsLoadingUploadImage(false)
                        const fortmatedData = res?.data?.map(
                          (i: any, index: number) => {
                            return {
                              type: cloneInfo[index]?.type.includes("video")
                                ? "video"
                                : "image",
                              url: i?.url,
                              preview: i?.url,
                              elements: [],
                              caption: "Bonus Image",
                              mimetype: cloneInfo[index]?.type.includes("video")
                                ? "video/*"
                                : "image/*",
                              width: 1080,
                              height: 1080,
                              ratio: 1.1,
                              seq: 0,
                              ...i,
                            };
                          }
                        );
                        formik.setFieldValue("files", fortmatedData);
                        //   const files = info.fileList;
                        //   console.log(info);
                        //   if (files?.length) {
                        //     const res = await uploadImage(files);
                        //     console.log(res);
                        //     await new Promise((r) => setTimeout(r, 2000));
                        //     setThumbnailURL(res[0]);
                        //   } else setThumbnailURL("");
                      } else {
                        setIsLoadingUploadImage(false)
                      }
                    } catch (error: any) {
                      setIsLoadingUploadImage(false)
                      toast(`${t('Input buffer contains unsupported image format')}`, CONFIG_OPTION_TOAST_ERROR);
                    }
                    
                  }}
                  onRemove={() => {
                    //return onGalleryFileRemove();
                  }}
                >
                  <Button icon={<UploadOutlined />}>
                    {t("Click to Upload")}
                  </Button>
                </Upload>
              </div>
            </Col>

            <div className="col-lg-12">
              <div className="hstack gap-2 justify-content-end">
                <button
                  className="btn btn-primary fs-14"
                  color="success"
                  type="submit"
                  disabled={
                    ((formik.errors && Object.keys(formik.errors).length > 0) || isLoading)
                      ? true
                      : false
                  }
                >
                  {isLoading ? (
                    <Spinner size="sm" className="me-2"></Spinner>
                  ) : isEdit ? (
                    <i className="ri-login-circle-line align-bottom me-1"></i>
                  ) : (
                    <i className="ri-add-fill align-bottom me-1"></i>
                  )}
                  {isEdit ? t("Update Feed") : t("Create Feed")}
                </button>
                <button
                  className="btn btn-soft-secondary fs-14"
                  color="light"
                  type="button"
                  onClick={onCloseClick}
                  disabled={isLoading}
                >
                  <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                  {t("Button Close")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}
