import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";

// Dashboard CRM
import DashboardReducer from "./dashboard/reducer";

//Category
import GroupReducer from "./group/reducer";

//ChatGPT
import ChatGPTReducer from "./chatgpt/reducer";

// Statistic
import StatisticReducer from "./statistic/reducer";

// User
import UserReducer from "./user/reducer";

// Role
import RoleReducer from "./role/reducer";


// Global
import GlobalReducer from "./global/reducer";


// Logging
import LoggingReducer from "./logging/reducer";


import { PostReducer, WallpaperReducer, WallpaperDownloadReducer } from "./post/reducer";
import { SnippetReducer, PhotoNewReducer } from "./snippet/reducer";
import AdvertisementReducer from "./advertisement/reducer";

// User Admin
import AdminReducer from "./admin/reducer";

// Artist
import ArtistReducer from "./artist/reducer"

// User Log
import LogReducer from "./log/reducer"

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  ForgetPassword: ForgetPasswordReducer,
  Profile: ProfileReducer,
  Dashboard: DashboardReducer,
  Group: GroupReducer,
  ChatGPT: ChatGPTReducer,
  Statistic: StatisticReducer,
  User: UserReducer,
  Role: RoleReducer,
  Global: GlobalReducer,
  Logging: LoggingReducer,
  Post: PostReducer,
  Wallpaper: WallpaperReducer,
  WallpaperDownload: WallpaperDownloadReducer,
  Snippet: SnippetReducer,
  PhotoNew: PhotoNewReducer,
  Advertisement: AdvertisementReducer,
  Admin: AdminReducer,
  Artist: ArtistReducer,
  Log: LogReducer
});

export default rootReducer;
