import BreadCrumb from "components/Common/BreadCrumb";
import TableContainer from "components/Common/TableContainer";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { createSelector } from "reselect";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import { useRole } from "components/Hooks/UserHooks";
import CountUp from "react-countup";
import { getUsers, putUser } from "store/user/thunk";
// import { truncate } from "utils";
import UserApi from "api/userApi";
// import { Switch } from "antd";
import DropdownOption from "components/Common/DropdownOption";
import {
  COLOR_STATUS_USER,
  ENUM_STATUS_USER,
  LIST_OPTION_GENDER,
  LIST_OPTION_OS,
  LIST_OPTION_PLATFORM,
  STATUS_USER_OPTION,
} from "helpers/constans";
import DropdownStatus from "components/Common/DropdownStatus";
import avatar1 from "../../../assets/images/users/ic_defaul_avatar2.png";
import DropdownCountries from "components/Common/DropdownCountries";
import countryApi from "api/countryApi";
import { set } from "lodash";
import { getPlatFormImage } from "helpers/constans";

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = "createdAt";

registerLocale("en", en);
registerLocale("ko", ko);

const LIST_OPTION_ROLE = [
  {
    id: 1,
    value: "normal",
    label: "Normal",
  },
  {
    id: 2,
    value: "kol",
    label: "Kol",
  },
  {
    id: 3,
    value: "biz",
    label: "Biz",
  },
];

const UserList = () => {
  const { t, i18n } = useTranslation();
  const dispatch: any = useDispatch();
  const { userPermissions } = useRole();

  const STATUS_USER_OPTION_LANG =
    STATUS_USER_OPTION?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    "sort[createdAt]": withDefault(StringParam, `desc`),
    // order_by: withDefault(StringParam, `DESC`),
    // date_version: withDefault(StringParam, ''),
    "condition[email]": withDefault(StringParam, ""),
    "condition[nickname]": withDefault(StringParam, ""),
    "condition[role]": withDefault(StringParam, ""),
    "condition[status]": withDefault(StringParam, ""),
    "condition[config][country]": withDefault(StringParam, ""),
    // platform
    "condition[providers][type]": withDefault(StringParam, ""),
    "condition[config][ipAddress]": withDefault(StringParam, ""),
    "condition[config][deviceOS]": withDefault(StringParam, ""),
    type: withDefault(StringParam, ""),
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<any>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

  const [itemToEdit, setItemToEdit] = useState<any>(false);

  const LIST_OPTION_ROLE_LANG =
    LIST_OPTION_ROLE?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];
  const LIST_OPTION_GENDER_LANG =
    LIST_OPTION_GENDER?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const LIST_OPTION_PLATFORM_LANG =
    LIST_OPTION_PLATFORM?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const LIST_OPTION_OS_LANG =
    LIST_OPTION_OS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const [nicknameSearch, setNicknameSearch] = useState<string>(
    query?.nickname || ""
  );
  const [emailSearch, setEmailSearch] = useState<string>(query?.email || "");
  const [roleSearch, setRoleSearch] = useState<Option | null>(
    LIST_OPTION_ROLE_LANG?.filter(
      (item) => String(item?.value) === String(query?.role)
    )[0]
  );
  const [platFormSearch, setPlatFormSearch] = useState<Option | null>(
    LIST_OPTION_PLATFORM_LANG?.filter(
      (item) => String(item?.value) === String(query?.role)
    )[0]
  );
  const [oSSearch, setOSSearch] = useState<Option | null>(
    LIST_OPTION_OS_LANG?.filter(
      (item) => String(item?.value) === String(query?.role)
    )[0]
  );
  const [linkImage, setLinkImage] = useState<string>("");
  const [countrySearch, setCountrySearch] = useState<any | null>(null);
  const [listCountry, setListCountry] = useState<any>([]);
  const [ipAddressSearch, setIPAddressSearch] = useState<string>(
    query?.ipAddress || ""
  );

  const [statusSearch, setStatusSearch] = useState<Option | null>(
    STATUS_USER_OPTION_LANG?.find(
      (item) => String(item?.value) === String(query["condition[status]"])
    ) || null
  );

  const [typesSearch, setTypesSearch] = useState<Option[]>([]);

  const selectLayoutState = (state: any) => state.User;

  const UserProperties = createSelector(selectLayoutState, (state) => ({
    users: state.users,
    isUserLoading: state.isUserLoading,
    isUserSuccess: state.isUserSuccess,

    error: state.error,
  }));

  // Inside your component
  const { users, isUserLoading } = useSelector(UserProperties);

  function loadTableData() {
    dispatch(getUsers(query));
  }

  useEffect(() => {
    loadTableData();
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setQuery({
      ...query,
      "condition[nickname]": nicknameSearch || "",
      "condition[email]": emailSearch || "",
      "condition[role]": roleSearch?.value || "",
      "condition[status]": statusSearch?.value || "",
      "condition[config][country]": countrySearch?.value || "",
      // platform
      "condition[providers][type]": platFormSearch?.value || "",
      "condition[config][deviceOS]": oSSearch?.value || "",
      "condition[config][ipAddress]": ipAddressSearch || "",
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: +new Date(),
    });
  };

  const resetData = () => {
    setQuery(
      {
        "condition[nickname]": "",
        "condition[email]": "",
        "condition[role]": "",
        "condition[status]": "",
        "condition[config][country]": "",
        // platform
        "condition[providers][type]": "",
        "condition[config][deviceOS]": "",
        "condition[config][ipAddress]": "",
        sort_by: "",
        time_request: +new Date(),
        page: 1,
      },
      "push"
    );
    setNicknameSearch((_prev) => "");
    setTypesSearch((_prev) => []);
    setStatusSearch((_prev) => null);
    setRoleSearch((_prev) => null);
    setPlatFormSearch((_prev) => null);
    setOSSearch((_prev) => null);
    setCountrySearch((_prev: any) => null);
    setIPAddressSearch((_prev) => "");
  };

  // Begin::Update User
  const onCloseClick = () => {
    setItemToEdit((_prev: any) => null);
    setIsOpen((_prev) => false);
  };

  const triggerRefresh = () => {
    onCloseClick();
    dispatch(getUsers(query));
  };
  //End::Update User

  // Begin::Change status
  const [itemUpdate, setItemUpdate] = useState<{
    id: number | string;
    status: string | boolean;
  } | null>(null);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const handleShowConfirmUpdate = (item: {
    id: number | string;
    status: string | boolean;
  }) => {
    setItemUpdate(item);
  };

  const handleOnConfirmUpdate = async () => {
    try {
      if (!itemUpdate) {
        return;
      }
      setIsLoadingUpdate((_prev) => true);
      const response: any = await putUser(itemUpdate?.id, {
        status: itemUpdate?.status,
      });
      setIsLoadingUpdate((_prev) => false);
      if (response?.success) {
        setItemUpdate((_prev) => null);
        dispatch(getUsers(query));
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingUpdate((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };
  // End::Change status

  function capitalizeFirstLetter(string: string) {
    if (!string) {
      return "";
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("No."),
        thWidth: 80,
        thClass: "whitespace-nowrap",
        Cell: ({ ...props }) => {
          return (
            <div>
              {users?.total -
                ((query?.page - 1) * query?.limit + props?.row?.index)}
            </div>
          );
        },
      },
      {
        Header: t("Avatar"),
        accessor: "avatar",
        thClass: "text-center whitespace-nowrap",
        filterable: true,
        sortable: false,
        thWidth: 170,
        Cell: (cell: any) => (
          <>
            <div className="w-100 text-center " style={{ minWidth: "60px" }}>
              <div
                style={{ width: "50px" }}
                className="m-auto cursor-pointer"
                onClick={() => setLinkImage((prev) => cell?.value ?? avatar1)}
              >
                <img
                  src={cell?.value ?? avatar1}
                  className="flex-shrink-0 rounded w-100"
                  alt="thumbnail"
                />
              </div>
            </div>
          </>
        ),
      },
      {
        Header: t("Nickname"),
        accessor: "nickname",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          return (
            <>
              <div className="cursor-pointer">{cell?.value}</div>
            </>
          );
        },
      },
      {
        Header: t("Col_Gender"),
        accessor: "gender",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          const gender = cell?.row?.original?.profile?.gender;

          return (
            <>
              <div className="cursor-pointer" style={{ width: "80px" }}>
                {LIST_OPTION_GENDER_LANG?.find(
                  (item: any) => String(item?.value) === String(gender)
                )?.label || "-"}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Email"),
        accessor: "email",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          return (
            <>
              <div className="cursor-pointer">{cell?.value}</div>
            </>
          );
        },
      },
      {
        Header: t("Platform"),
        accessor: "providers_platform",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          const items = cell?.row?.original?.providers || [];

          const formattedPlatform = items?.map((item: any) =>
            item.type === "firebase" || item.type === "apple"
              ? "apple"
              : item.type
          );
          const formatData = Array.from(new Set(formattedPlatform));

          return (
            formatData?.length > 0 && (
              <div className="d-flex flex-column gap-1">
                {formatData?.map((item: any, index: number) => {
                  let imgPlatform = getPlatFormImage(item);

                  return (
                    <div
                      key={index}
                      className="cursor-pointer d-flex gap-2 align-items-center "
                    >
                      <div style={{ width: "25px" }}>
                        {imgPlatform && (
                          <img
                            src={getPlatFormImage(item)}
                            width={25}
                            height={25}
                            alt="platform"
                          />
                        )}
                      </div>
                      <div className="cursor-pointer">
                        {capitalizeFirstLetter(
                          item == "default" ? "Email" : item
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )
          );
        },
      },
      {
        Header: t("deviceOS"),
        accessor: "",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          const deviceOS = cell?.row?.original?.config?.deviceOS;
          return (
            deviceOS && (
              <div className="cursor-pointer" style={{ width: 80 }}>
                {t(`${deviceOS}`)}
              </div>
            )
          );
        },
      },
      {
        Header: t("IP"),
        accessor: "providers_ip",
        thClass: "whitespace-nowrap",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const ipAddress = cell?.row?.original?.config?.ipAddress;
          return ipAddress && <div className="cursor-pointer">{ipAddress}</div>;
        },
      },
      {
        Header: t("Country"),
        accessor: "country",
        thClass: "whitespace-nowrap",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const items = cell?.row?.original?.config?.countryDetail || [];

          return (
            items?.length > 0 && (
              <div className="d-flex flex-column gap-1">
                {
                  <div className="cursor-pointer d-flex gap-2 align-items-center ">
                    {/* { item?.profileImage && <img src={item?.profileImage} width={30} height={30} alt="profile" style={{borderRadius: 10}} /> } */}
                    <div className="cursor-pointer">{items?.[0]?.name}</div>
                  </div>
                }
              </div>
            )
          );
        },
      },
      {
        Header: t("Role"),
        accessor: "role",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          const role =
            LIST_OPTION_ROLE_LANG.filter(
              (item) => item.value == cell?.value
            )[0] || "";
          return (
            <>
              <div className="cursor-pointer">{role?.label}</div>
            </>
          );
        },
      },
      {
        Header: t("Status"),
        accessor: "_status",
        filterable: false,
        sortable: false,
        thWidth: 100,
        thClass: "text-center whitespace-nowrap",
        Cell: (cell: any) => {
          const status = cell?.row?.original?.status;
          return (
            <div className="text-center" style={{ minWidth: "80px" }}>
              <span
                className={`rounded-pill badge bg-${
                  COLOR_STATUS_USER[status] || "secondary"
                }`}
              >
                {
                  STATUS_USER_OPTION_LANG?.find(
                    (item: any) => String(item?.value) === String(status)
                  )?.label
                }
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Active"),
        accessor: "status",
        thWidth: 140,
        thClass: "text-center whitespace-nowrap",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div
              className="form-check form-switch form-switch-sm text-center"
              dir="ltr"
            >
              <input
                type="checkbox"
                className="form-check-input"
                name="status"
                id="customSwitchsizelg"
                checked={String(item?.status) === ENUM_STATUS_USER.ACTIVATED}
                onChange={(e) => {
                  const value = e?.target?.checked;
                  handleShowConfirmUpdate({
                    id: item?._id,
                    status: !!value
                      ? ENUM_STATUS_USER.ACTIVATED
                      : ENUM_STATUS_USER.DISABLED,
                  });
                }}
              />
            </div>
          );
        },
      },
      {
        Header: t("Last Login Time"),
        accessor: "lastLoginTime",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end whitespace-nowrap",
        Cell: (cell: any) => {
          const lastLoginTime = cell?.row?.original?.config?.lastLoginTime;
          const date = moment(lastLoginTime)
            .utc()
            .format("YYYY-MM-DD HH:mm:ss");
          const arrDate = String(date || "").split(" ");

          return (
            <div className="text-end" style={{ width: "100px" }}>
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Created at"),
        accessor: "createdAt",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end whitespace-nowrap",
        Cell: (cell: any) => {
          const date = moment(cell?.value).utc().format("YYYY-MM-DD HH:mm:ss");
          const arrDate = String(date || "").split(" ");

          return (
            <div className="text-end" style={{ width: "100px" }}>
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      // {
      //   Header: t("Updated at"),
      //   accessor: "updatedAt",
      //   filterable: true,
      //   sortable: false,
      //   thWidth: 130,
      //   thClass: "text-end whitespace-nowrap",
      //   Cell: (cell: any) => {
      //     const date = moment(cell?.value).utc().format("YYYY-MM-DD HH:mm:ss");
      //     const arrDate = String(date || "").split(" ");

      //     return (
      //       <div className="text-end">
      //         <span>
      //           <span>{arrDate?.[0] || ""}</span> <br />{" "}
      //           <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
      //         </span>
      //       </div>
      //     );
      //   },
      // },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n?.language, userPermissions]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Begin::Delete

  const handleActionDelete = async (id: any) => {
    if (!id) {
      return;
    }
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await UserApi?.deleteUser(id);
      console.log(response);
      if (response?.success) {
        loadTableData();
        setIsConfirmLoading((_prev) => false);
        setItemToDelete(false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  // End::Delete

  useEffect(() => {
    document.title = `${t("Active User")} - ${t("User")} | Dispatch`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    const handleCallAllOption = async () => {
      try {
        const [resCountry]: any = await Promise.all([
          countryApi.countries({ page: 1, limit: 20000 }),
        ]);
        setListCountry((_prev: any) => resCountry?.data || []);
      } catch (error: any) {
        return error;
      }
    };

    handleCallAllOption();
  }, []);

  const onCloseImageClick = (e: any) => {
    setLinkImage((_prev) => "");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={t("Active User Management")}
            pageTitle={t("Home")}
          />
          <Row>
            <Col lg={12}>
              <Card
                id="customerList"
                style={{ boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)" }}
              >
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">
                          <span className="me-2">{t("Total")}:</span>
                          <CountUp
                            start={0}
                            end={users?.total || 0}
                            duration={1}
                            className="text-primary"
                          />
                        </h5>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                  <Row className="g-4 align-items-center mt-0 mt-md-2">
                    <Col sm={12} md={2} xl={2} xxl={2} className="mt-3 mt-md-2">
                      <LabelWrapper
                        label={t("Nickname")}
                        isShow={!!nicknameSearch}
                      >
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={`${t("Nickname")}...`}
                          value={nicknameSearch}
                          onChange={(e) => setNicknameSearch(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                        />
                      </LabelWrapper>
                    </Col>

                    <Col sm={12} md={2} xl={2} xxl={2} className="mt-3 mt-md-2">
                      <LabelWrapper label={t("Email")} isShow={!!emailSearch}>
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={`${t("Email")}...`}
                          value={emailSearch}
                          onChange={(e) => setEmailSearch(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                        />
                      </LabelWrapper>
                    </Col>

                    <Col sm={12} md={2} xl={2} xxl={2} className="mt-3 mt-md-2">
                      <LabelWrapper
                        label={t("IP Address")}
                        isShow={!!ipAddressSearch}
                      >
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={`${t("IP Address")}...`}
                          value={ipAddressSearch}
                          onChange={(e) => setIPAddressSearch(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                        />
                      </LabelWrapper>
                    </Col>

                    <Col sm={12} md={2} xl={2} xxl={2} className="mt-3 mt-md-2">
                      <div>
                        <LabelWrapper
                          label={t("Role")}
                          isShow={!!roleSearch?.value}
                        >
                          <DropdownOption
                            name="role"
                            dataList={LIST_OPTION_ROLE_LANG || []}
                            placeholder={`${t("Role")}...`}
                            className="dropdown-status-rounded"
                            classNamePrefix="name-prefix"
                            initialValue={roleSearch || null}
                            onChangeSelect={(e: any) => setRoleSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t("All Role"), value: "" }}
                          />
                        </LabelWrapper>
                      </div>
                    </Col>

                    <Col sm={12} md={2} xl={2} xxl={2} className="mt-3 mt-md-2">
                      <LabelWrapper label={t("Status")} isShow={true}>
                        <DropdownStatus
                          name="status"
                          dataList={STATUS_USER_OPTION_LANG || []}
                          placeholder={`${t("Status")}...`}
                          className="dropdown-status-rounded"
                          classNamePrefix="name-prefix"
                          initialValue={statusSearch || null}
                          onChangeSelect={(e: any) => setStatusSearch(e)}
                          isHasOptionAll={true}
                          optionAll={{ label: t("All Filter"), value: "" }}
                          colors={COLOR_STATUS_USER}
                        />
                      </LabelWrapper>
                    </Col>

                    <Col
                      sm={12}
                      md={2}
                      xl={2}
                      xxl={2}
                      className="mt-sm-4 mt-md-3"
                    >
                      <LabelWrapper label={t("Platform")} isShow={true}>
                        <DropdownOption
                          name="platform"
                          dataList={LIST_OPTION_PLATFORM_LANG || []}
                          placeholder={`${t("Platform")}...`}
                          className="dropdown-status-rounded"
                          classNamePrefix="name-prefix"
                          initialValue={platFormSearch || null}
                          onChangeSelect={(e: any) => setPlatFormSearch(e)}
                          isHasOptionAll={true}
                          optionAll={{ label: t("All Filter"), value: "" }}
                        />
                      </LabelWrapper>
                    </Col>

                    <Col
                      sm={12}
                      md={2}
                      xl={2}
                      xxl={2}
                      className="mt-sm-4 mt-md-3"
                    >
                      <LabelWrapper label={t("OS")} isShow={true}>
                        <DropdownOption
                          name="os"
                          dataList={LIST_OPTION_OS_LANG || []}
                          placeholder={`${t("OS")}...`}
                          className="dropdown-status-rounded"
                          classNamePrefix="name-prefix"
                          initialValue={oSSearch || null}
                          onChangeSelect={(e: any) => setOSSearch(e)}
                          isHasOptionAll={true}
                          optionAll={{ label: t("All Filter"), value: "" }}
                        />
                      </LabelWrapper>
                    </Col>

                    <Col
                      sm={12}
                      md={2}
                      xl={2}
                      xxl={2}
                      className="mt-sm-4 mt-md-3"
                    >
                      <LabelWrapper label={t("Country")} isShow={true}>
                        <DropdownCountries
                          name="country"
                          dataList={listCountry || []}
                          placeholder={`${t("Select Country")}...`}
                          className="dropdown-status-rounded"
                          classNamePrefix="name-prefix"
                          initialValue={countrySearch || null}
                          onChangeSelect={(e: any) => setCountrySearch(e)}
                          isHasOptionAll={true}
                          optionAll={{ label: t("All Filter"), value: "" }}
                        />
                      </LabelWrapper>
                    </Col>

                    <Col
                      sm={12}
                      md={12}
                      lg={12}
                      className="hstack gap-1 justify-content-sm-center justify-content-md-end mt-sm-4 mt-md-3"
                    >
                      <div>
                        <button
                          type="button"
                          className="btn btn-primary me-1"
                          onClick={searchData}
                        >
                          <i className="ri-search-line align-bottom me-1"></i>{" "}
                          {t("Button Search")}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary fs-14"
                          onClick={resetData}
                        >
                          <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                          {t("Button Reset")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle table-bordered-dashed"
                      theadClass="table-light text-muted"
                      columns={columns}
                      data={users?.items?.length ? users?.items : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={users?.total}
                      customPageCount={Math.ceil(
                        Number(users?.total) / Number(query.limit)
                      )}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{
                        sort_by: query.sort_by,
                        order_by: query.order_by,
                      }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isUserLoading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {itemToDelete && (
          <ModalConfirm
            header={t("Delete User")}
            isOpen={itemToDelete}
            isLoading={isConfirmLoading}
            onClose={() => setItemToDelete(false)}
            onConfirm={() => handleActionDelete(itemToDelete?._id)}
          />
        )}

        <ModalConfirm
          textButtonConfirm={t("Button Update")}
          classButtonConfirm="btn-primary"
          classIconButtonConfirm=""
          header={
            String(itemUpdate?.status) === ENUM_STATUS_USER.ACTIVATED
              ? t("Confirm to ON")
              : t("Confirm to OFF")
          }
          title={t("Are you sure you want to update this?")}
          content={t(
            "After confirming the update action, the data will be updated. Do you want to proceed with the update action."
          )}
          isShowIcon={false}
          isOpen={!!itemUpdate}
          isLoading={isLoadingUpdate}
          onClose={() => setItemUpdate((prev: any) => null)}
          onConfirm={handleOnConfirmUpdate}
        />

        <Modal
          isOpen={!!linkImage}
          centered={true}
          size="lg"
          scrollable={true}
          toggle={onCloseImageClick}
          keyboard={true}
        >
          <ModalBody className="text-center">
            <img
              src={linkImage || ""}
              className="flex-shrink-0 rounded"
              alt="chart"
            />
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default UserList;
