import { createSlice } from "@reduxjs/toolkit";
import { getPosts, getWallpapers, getWallpapersDownload } from "./thunk";

export interface IState {
  posts: any;
  isPostLoading: boolean;
  isPostSuccess: boolean;

  error: any;
}

export const initialState: IState = {
  posts: null,
  isPostLoading: false,
  isPostSuccess: false,

  error: {},
};

const PostSlice = createSlice({
  name: "Post",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Posts
    builder.addCase(getPosts.pending, (state: IState, action: any) => {
      state.isPostLoading = true;
    });
    builder.addCase(getPosts.fulfilled, (state: IState, action: any) => {
      state.posts = action.payload.data;
      state.isPostSuccess = true;
      state.isPostLoading = false;
    });
    builder.addCase(getPosts.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isPostSuccess = false;
      state.isPostLoading = false;
    });
  },
});

const WallpaperSlice = createSlice({
  name: "Wallpaper",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Posts
    builder.addCase(getWallpapers.pending, (state: IState, action: any) => {
      state.isPostLoading = true;
    });
    builder.addCase(getWallpapers.fulfilled, (state: IState, action: any) => {
      state.posts = action.payload.data;
      state.isPostSuccess = true;
      state.isPostLoading = false;
    });
    builder.addCase(getWallpapers.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isPostSuccess = false;
      state.isPostLoading = false;
    });
  },
});

const WallpaperDownloadSlice = createSlice({
  name: "WallpaperDownload",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Posts
    builder.addCase(getWallpapersDownload.pending, (state: IState, action: any) => {
      state.isPostLoading = true;
    });
    builder.addCase(getWallpapersDownload.fulfilled, (state: IState, action: any) => {
      state.posts = action.payload.data;
      state.isPostSuccess = true;
      state.isPostLoading = false;
    });
    builder.addCase(getWallpapersDownload.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isPostSuccess = false;
      state.isPostLoading = false;
    });
  },
});

export const PostReducer = PostSlice.reducer;
export const WallpaperReducer = WallpaperSlice.reducer;
export const WallpaperDownloadReducer = WallpaperDownloadSlice.reducer;