import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Import Icons
import { useRole } from "components/Hooks/UserHooks";
import FeatherIcon from "feather-icons-react";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ROUTES } from "routes/allRoutes";
import config from "config";

const Navdata = () => {
  const history = useNavigate();
  const { t } = useTranslation();
  const { userPermissions } = useRole();

  const [menuActive, setMenuActive] = useState<string>("DASHBOARD");

  // Multi Level
  const [isMultiLevel, setIsMultiLevel] = useState<boolean>(false);
  const [isLevel1, setIsLevel1] = useState<boolean>(false);
  const [isLevel2, setIsLevel2] = useState<boolean>(false);

  const [isCurrentState, setIsCurrentState] = useState("Dashboard");

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("sub-items")) {
      const ul: any = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("sub-items");
        const getID = document.getElementById(id) as HTMLElement;
        if (getID) getID.classList.remove("show");
      });
    }
  }

  const redirectMessageHub = () => {
    const { message_hub_sso_redirect_url } = config;
    const accessToken: any = localStorage.getItem("access_token");

    if (message_hub_sso_redirect_url && accessToken) {
      return `${message_hub_sso_redirect_url}?sso_token_newshub=${accessToken}`;
    } else {
      return "/#";
    }
  };

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
  }, [history]);

  const menuItems: any = [
    {
      id: "content-management",
      label: t("Content Management"),
      icon: <FeatherIcon icon="airplay" className="icon-dual" />,
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setMenuActive((prev) => "Feed");
        setIsCurrentState((prev) => "Feed");
        updateIconSidebar(e);
      },
      stateVariables: menuActive === "Feed",
      isShow: true,
      subItems: [
        {
          id: "feed",
          label: t("Feed"),
          link: ROUTES.FEED,
          isShow: true,
        },
        {
          id: "wallpaper",
          label: t("Wallpaper"),
          link: ROUTES.WALLPAPER,
          isShow: true,
        },
        // {
        //   id: "wallpaper-download",
        //   label: t("Wallpaper Download"),
        //   link: ROUTES.WALLPAPER_DOWNLOAD,
        //   isShow: true,
        // },
        {
          id: "snap",
          label: t("Snap"),
          link: ROUTES.SNAP,
          isShow: true,
        },
        {
          id: "photo-news",
          label: t("Photo News"),
          link: ROUTES.PHOTO_NEWS,
          isShow: true,
        },
      ],
    },
    {
      id: "artist-management",
      label: t("Artist management"),
      icon: <FeatherIcon icon="command" className="icon-dual" />,
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setMenuActive((prev) => "Artist");
        setIsCurrentState((prev) => "Artist");
        updateIconSidebar(e);
      },
      stateVariables: menuActive === "Artist",
      isShow: true,
      subItems: [
        {
          id: "group",
          label: t("Group"),
          link: ROUTES.GROUP,
          isShow: true,
        },
        {
          id: "artist",
          label: t("Artist"),
          link: ROUTES.ARTIST,
          isShow: true,
        },
      ],
    },
    {
      id: "user-management",
      label: t("User Management"),
      icon: <FeatherIcon icon="users" className="icon-dual" />,
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setMenuActive((prev) => "User");
        setIsCurrentState((prev) => "User");
        updateIconSidebar(e);
      },
      stateVariables: menuActive === "User",
      isShow: true,
      subItems: [
        {
          id: "user",
          label: t("Active User"),
          link: ROUTES.USER,
          isShow: true,
        },
        {
          id: "delete-user",
          label: t("Deleted User"),
          link: ROUTES.USER_DELETE,
          isShow: true,
        },
        {
          id: "user-admin",
          label: t("Admin User"),
          link: ROUTES.USER_ADMIN,
          isShow: true,
        },
        {
          id: "user-logs",
          label: t("User Log"),
          link: ROUTES.USER_LOG,
          isShow: true,
        },
        {
          id: "login-history",
          label: t("Login History"),
          link: ROUTES.LOGIN_HISTORY,
          isShow: true,
        },
        {
          id: "user-feedback",
          label: t("User Feedback"),
          link: ROUTES.USER_FEEDBACK,
          isShow: true,
        },
        {
          id: "admin-logs",
          label: t("Admin Log"),
          isShow: true,
          link: ROUTES.ADMIN_LOG,
        },
      ],
    },
    // {
    //   id: "artist-management",
    //   label: t("Artist management"),
    //   icon: <FeatherIcon icon="command" className="icon-dual" />,
    //   link: "/#",
    //   click: function (e: any) {
    //     e.preventDefault();
    //     setMenuActive((prev) => "Artist");
    //     setIsCurrentState((prev) => "Artist");
    //     updateIconSidebar(e);
    //   },
    //   stateVariables: menuActive === "Artist",
    //   isShow: true,
    //   subItems: [
    //     {
    //       id: "group",
    //       label: t("Group"),
    //       link: ROUTES.GROUP,
    //       isShow: true,
    //     },
    //     {
    //       id: "artist",
    //       label: t("Artist"),
    //       link: ROUTES.ARTIST,
    //       isShow: true,
    //     },
    //   ],
    // },
    {
      id: "multilevel",
      label: "Statistics",
      icon: <FeatherIcon icon="bar-chart-2" className="icon-dual" />,
      link: "/#",
      isShow: true,
      click: function (e: any) {
        e.preventDefault();
        setIsMultiLevel(!isMultiLevel);
        setIsCurrentState("Statistics");
        updateIconSidebar(e);
      },
      stateVariables: isMultiLevel,
      subItems: [
        {
          id: "daily-users",
          label: t("Daily users"),
          link: ROUTES.STATISTICS_DAILY_USER,
          parentId: "multilevel",
          isShow: true,
        },
        {
          id: "level1.2",
          label: t("Content"),
          link: "/#",
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault();
            setIsLevel1(!isLevel1);
          },
          stateVariables: isLevel1,
          isShow: true,
          childItems: [
            {
              id: 1,
              label: t("Summary statistics"),
              link: ROUTES.STATISTICS_SUMMARY,
              isShow: true,
            },
            {
              id: 2,
              label: t("Feed (Hot) statistics"),
              link: ROUTES.STATISTICS_HOT,
              isShow: true,
            },
            {
              id: 3,
              label: t("Wallpaper statistics"),
              link: ROUTES.STATISTICS_WALLPAPER,
              isShow: true,
            },
            {
              id: 4,
              label: t("Photo News statistics"),
              link: ROUTES.STATISTICS_PHOTOCARD_NEWS,
              isShow: true,
            },
            {
              id: 5,
              label: t("Snap statistics"),
              link: ROUTES.STATISTICS_SNAP,
              isShow: true,
            },
          ],
        },
      ],
    },
    {
      id: "configuration-settings",
      label: t("Settings"),
      icon: <FeatherIcon icon="film" className="icon-dual" />,
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setMenuActive((prev) => "Settings");
        setIsCurrentState((prev) => "Settings");
        updateIconSidebar(e);
      },
      stateVariables: menuActive === "Settings",
      isShow: true,
      subItems: [
        {
          id: "advertisement",
          label: t("Advertisement"),
          link: ROUTES.ADVERTISEMENT,
          isShow: true,
        },
      ],
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
